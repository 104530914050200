import React from 'react';
import styled from 'styled-components';

interface IProps {
  open: boolean;
}

const MobileMenu = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  height: 100vh;
  padding: 2rem;
  position: absolute;
  top: 80px;
  right: 0;
  width: 300px;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 700px) {
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
    transform: ${({ open }) => (open ? 'translateX(0%)' : 'translateX(100%)')};
  }

  @media (min-width: 700px) {
    height: fit-content;
    width: fit-content;
    flex-direction: row;
    position: static;
    background: transparent;
  }
`;

const Menu: React.FC<IProps> = ({ children, open }) => (
  <MobileMenu open={open}>{children}</MobileMenu>
);

export default Menu;
