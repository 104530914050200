import { useState, useEffect } from 'react';

import { Hub } from '@aws-amplify/core';
import Auth from '../utils/authProvider';

export const useLogin = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const getUser = () => Auth.currentAuthenticatedUser();

  useEffect(() => {
    let isMounted = true;
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.log('cognitoHostedUI');
          getUser().then((userData) => {
            setUser(userData);
          });
          break;
        case 'signOut':
          console.log('singout');
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          console.log(event);
      }
    });

    getUser()
      .then(async (userData) => {
        if (isMounted) setUser(userData);
      })
      .catch((err) => {
        console.log(err);
        Auth.federatedSignIn();
      })
      .finally(() => setLoading(false));

    return () => {
      isMounted = false;
    };
  }, []);

  return { loading, user };
};

