import React from 'react';
import styled from 'styled-components';

const Submit = styled.input`
  width: 80%;
  max-width: 300px;
  margin: 12px auto;
  padding: 8px;
  background: #7d8c7f;
  display: block;
  color: #ffffff;
  border: 2px solid #7d8c7f;
  font-family: 'VisueltPro', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  transition: 0.3s ease all;
  cursor: pointer;
  &:hover {
    color: #7d8c7f;
    background: transparent;
  }
`;

interface IProps {
  form: string;
  value: string;
}

const SubmitButton: React.FC<IProps> = ({ form, value }) => (
  <Submit type="submit" form={form} value={value} />
);

export default SubmitButton;
