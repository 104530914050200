import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  width: 100%;
  margin: 16px auto 4px auto;
`;

interface IProps {
  name: string;
  id: string;
  onSubmit: any;
}

const SelectionForm: React.FC<IProps> = ({
  name,
  id,
  children,
  onSubmit,
}) => (
  <Form name={name} id={id} onSubmit={(e) => onSubmit(e)}>
    {children}
  </Form>
);

export default SelectionForm;
