import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 480px;
  max-width: 400px;
  padding: 16px 20px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 17px 17px rgba(0, 0, 0, 0.25);
`;

const FormWrapper: React.FC<React.ReactNode> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default FormWrapper;
