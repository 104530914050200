import React from 'react';
import styled from 'styled-components';

const Navbar = styled.header`
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Header: React.FC = ({ children }) => (
  <Navbar>{children}</Navbar>
);

export default Header;
