import { RefObject, useEffect } from 'react';

// Custom hook that makes closing the mobile menu by clicking outside possible
export const useOnClickOutside = (
  ref: RefObject<any>,
  handler: (event: Event) => void
): void => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
