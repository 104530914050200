import React from 'react';
import styled from 'styled-components';

const MainLogo = styled.img`
  height: 60px;
  width: auto;
`;

interface IProps {
  logo: string;
  alt: string;
}

const Logo: React.FC<IProps> = ({ logo, alt }) => (
  <MainLogo src={logo} alt={alt} />
);

export default Logo;
