import { useState } from 'react';
import { IFormValues } from '../interfaces/interfaces';

export const useForm = (initialValues: IFormValues): [IFormValues, string, (e: { target: HTMLSelectElement}) => void, (args: IFormValues) => void] => {
  const [values, setValues] = useState<IFormValues>(initialValues);
  const [fetchType, setFetchType] = useState('initial')

  return [
    values,
    fetchType,
    (e: { target: HTMLSelectElement}) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
      setFetchType(e.target.name)
    }, (args: IFormValues) => {
      setValues(args)
    }
  ]
} 