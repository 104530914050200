import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const TitleContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;
const Title = styled.h1`
  font-family: 'Univers';
  font-size: 2.488rem;
  margin: 4px 0;
`;

const Logo = styled.img`
  height: 80px;
  width: auto;
  margin: 8px auto;
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #000000;
`;

const Description = styled.p`
  font-family: 'VisueltPro';
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
`;

interface IProps {
  logo: string;
  alt: string;
  title: string;
}

const FormDescription: React.FC<IProps> = ({ logo, alt, title, children }) => (
  <MainContainer>
    <Logo src={logo} alt={alt} />
    <TitleContainer>
      <Divider />
      <Title>{title}</Title>
    </TitleContainer>
    <Description>{children}</Description>
  </MainContainer>
);

export default FormDescription;
