import { useState, useRef } from 'react';
import { useFetch } from './hooks/useFetch';
import { useForm } from './hooks/useForm';
import { useLogin } from './hooks/useLogin';
import { useArguments } from './hooks/useArguments';
import { useOnClickOutside } from './hooks/useOnClickOutside';
import Result from './pages/Result';
import Home from './pages/Home';
import Header from './components/Header';
import Logo from './components/Logo';
import logoSrc from './assets/img/ohpenlogo.png';
import MenuButton from './components/MenuButton';
import Menu from './components/Menu';
import Burger from './components/Burger';
import Notification from './components/Notification';
import VersionDescription from './components/VersionDescription';
import { FaLink, FaDownload, FaArrowLeft } from 'react-icons/fa';

function App() {
  const [values, fetchType, handleSelect, resetValues] = useForm({
    workspace: 'Select the workspace',
    api: 'Select the API',
    version: 'Select the version',
  });
  const [fetchInfo, updateRender] = useArguments(values, fetchType);
  const [{ data, loading, error }, toggleLoading] = useFetch(
    fetchInfo,
    updateRender
  );
  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState({ type: '', content: '' });
  const [schemaUrl, setSchemaUrl] = useState('');
  const [result, setResult] = useState(false);
  const node = useRef<HTMLDivElement | null>(null);
  const { user } = useLogin();

  useOnClickOutside(node, () => setOpen(false));

  const handleSubmit = (e: Event): void => {
    if (
      values.workspace !== 'Select the workspace' &&
      values.api !== 'Select the api' &&
      values.version !== 'Select the version'
    ) {
      e.preventDefault();
      toggleLoading();
      setSchemaUrl(
        `https://api.getpostman.com/apis/${values.api}/versions/${values.version}/schemas/${data.schemaId}`
      );
      setTimeout(() => {
        toggleLoading();
        setResult(true);
      }, 800);
    } else {
      window.alert('Please fill in all fields');
    }
  };

  const backToHome = () => {
    setResult(!result);
    resetValues({
    ...values,
    api: 'Select the API',
    version: 'Select the version'});
    toggleLoading();
  };

  const displayNotification = (message: any) => {
    setMessage(message);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
      setMessage({ type: '', content: '' });
    }, 5000);
  };

  // Download the API schema file (YAML)
  const downloadFile = async () => {
    const fileName = 'openapi';
    const blob = new Blob([JSON.stringify(data.schemaYAML, null, 2)], {
      type: 'text/yaml',
    });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.yaml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {user === null ? (
        <></>
      ) : (
        <>
          {showNotification && <Notification message={message} />}
          <Header>
            <Logo logo={logoSrc} alt="Ohpen Logo" />
            {result ? (
              <div ref={node}>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open}>
                  <MenuButton onClick={backToHome}>
                    <FaArrowLeft />
                    Back to search
                  </MenuButton>
                  {data.schemaYAML && !error && (
                    <MenuButton primary="primary" onClick={() => {
                      downloadFile()
                      displayNotification({
                        type: 'success',
                        content: 'Schema succesfully downloaded',
                      });
                    }}>
                      <FaDownload />
                      Download
                    </MenuButton>
                  )}
                  {schemaUrl && !error && (
                    <MenuButton
                      primary="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(schemaUrl);
                        displayNotification({
                          type: 'success',
                          content: 'Schema URL copied to clipboard',
                        });
                      }}
                    >
                      <FaLink />
                      Copy link
                    </MenuButton>
                  )}
                </Menu>
              </div>
            ) : (
              <VersionDescription>v1.1.0</VersionDescription>
            )}
          </Header>
          {result ? (
            <Result
              error={error}
              schemaYAML={data.schemaYAML}
              api={values.api}
              version={values.version}
            />
          ) : (
            <Home
              workspaces={data.workspaces}
              apis={data.apis}
              versions={data.versions}
              workspace={values.workspace}
              api={values.api}
              version={values.version}
              isLoading={loading}
              handleSubmit={handleSubmit}
              handleSelect={handleSelect}
            />
          )}
        </>
      )}
    </>
  );
}

export default App;
