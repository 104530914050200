import { useEffect, useState } from "react";
import { IFetchInfo, IFormValues } from "../interfaces/interfaces";


export const useArguments = (values: IFormValues, fetchType: string ): [IFetchInfo, boolean] => {
  const [updateRender, setUpdateRender] = useState(false);
  const [fetchInfo, setFetchInfo] = useState({ url: '', type: 'loading' });

  useEffect(() => {
    if (
      values.workspace !== 'Select the workspace' &&
      values.api !== 'Select the API' &&
      values.version !== 'Select the version' && fetchType === 'version'
    ) {
      setFetchInfo({
        url: `/apis/${values.api}/versions/${values.version}`,
        type: 'schemas',
      });
    } else if (
      values.workspace !== 'Select the workspace' &&
      values.api !== 'Select the API' && fetchType === 'api'
    ) {
      setFetchInfo({ url: `/apis/${values.api}/versions`, type: 'versions' });
    } else if (values.workspace !== 'Select the workspace' && fetchType === 'workspace') {
      setFetchInfo({
        url: `/apis?workspace=${values.workspace}`,
        type: 'apis',
      });
    } else {
      setFetchInfo({ url: '/workspaces', type: 'workspaces' });
    }
    setUpdateRender(!updateRender);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);


  return [fetchInfo, updateRender]
}