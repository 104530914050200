import { useEffect, useState } from 'react';
import { IFetchState, ILabel, IFetchInfo } from '../interfaces/interfaces';
import { workspacesIdToInclude, apisIdToExclude } from '../utils/exclusions';
import axios from 'axios';
import * as YAML from 'js-yaml';

axios.defaults.baseURL = 'https://api.getpostman.com';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['X-API-Key'] = window.atob(process.env.REACT_APP_POSTMAN_API_KEY || "");

export const useFetch = (
  fetchInfo: IFetchInfo,
  updateRender: boolean
): [IFetchState, () => void] => {
  const { url, type } = fetchInfo;
  const [state, setState] = useState<IFetchState>({
    data: {
      workspaces: [],
      apis: [],
      versions: [],
      schemaId: '',
      schemaYAML: null,
    },
    loading: false,
    error: false,
  });

  useEffect(() => {
    setState({ ...state, loading: true });
    if (type === 'loading') {
      setState({ ...state, loading: false });
      return;
    }

    const getDataFromUrl = async () => {
      let fetchedData: any[];
      let filteredData: any[];

      if (type === 'schemas') {
        const fetchedDataString: string = await fetchRequest(url, type).then(
          (schemas) => schemas.version.schema[0]
        );

        const fetchedSchema: any = await fetchRequest(
          url + `/schemas/${fetchedDataString}`,
          'schema'
        );

        const schema: any = YAML.load(fetchedSchema);

        setState({
          ...state,
          data: {
            ...state.data,
            schemaId: fetchedDataString,
            schemaYAML: schema,
          },
        });
      } else {
        fetchedData = await fetchRequest(url, type).then((x) =>
          x[type].map((data: any) => ({
            name: data.name,
            id: data.id,
          }))
        );

        if (type === 'workspaces') {
          filteredData = fetchedData.filter((workspace: ILabel) =>
            workspacesIdToInclude.includes(workspace.id)
          );
        } else if (type === 'apis') {
          filteredData = fetchedData
            .filter((api: ILabel) => !apisIdToExclude.includes(api.id))
            .sort((a: ILabel, b: ILabel) => (a.name > b.name ? 1 : -1));
        } else {
          filteredData = fetchedData;
        }

        setState({
          ...state,
          data: {
            ...state.data,
            [type]: filteredData,
          },
        });
      }
    };

  
      try {
        getDataFromUrl();
      } catch (err) {
        setState({ ...state, error: true });
      }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRender]);

  return [
    state,
    () => {
      setState({ ...state, loading: !state.loading });
    },
  ];
};

// Helper function for fetching
async function fetchRequest(path: string, type: string): Promise<any> {
  try {
    let res = await axios({
      url: path,
      method: 'get',
      timeout: 8000,
    });
    if (res.status === 200) {
      console.log(res.status);
    }

    return type === 'schema' ? res.data.schema.schema : res.data;
  } catch (err) {
    console.error(err);
  }
}
