import React from 'react'
import './Notification.css'

interface IProps {
  message: {
    type: string,
    content: string
  }
}

const Notification: React.FC<IProps> = ({ message }) => {
  return (
    <div className={message.type +  ' notification-box'}>
      <p>{message.content}</p>
    </div>
  )
}

export default Notification
