import React from 'react';
import styled from 'styled-components';
import { ILabel } from '../../interfaces/interfaces';

const Select = styled.select`
  padding: 8px 4px;
  border: 1px solid #f8f8f8;
  width: 80%;
  max-width: 300px;
  margin: 8px auto;
  display: block;
  outline: 1px solid #aaaaaa;
  color: #aaaaaa;
  font-family: 'VisueltPro', sans-serif;
  font-weight: 300px;
`;

interface IProps {
  options: ILabel[] | [];
  name: string;
  id: string;
  onSelect?: any;
  value: any;
  defaultValue: any;
}

const Dropdown: React.FC<IProps> = ({ options, name, id, onSelect, value, defaultValue }) => {

  return (
    <Select name={name} id={id} value={value} onChange={(e) => onSelect(e)}>
      <option value={defaultValue} disabled>
        {defaultValue}
      </option>
      {options &&
        options.map((option: ILabel) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
    </Select>
  );
};

export default Dropdown;
