import Auth from '@aws-amplify/auth';

const oauth = {
  // Domain name
  domain: 'auth.tools.dev.ohpen.tech',
  // Authorized scopes
  scope: [
    'phone',
    'email',
    'profile',
    'openid',
    'aws.cognito.signin.user.admin',
  ],
  // Callback URL
  redirectSignIn: process.env.REACT_APP_AWS_COGN_REDIRECT_URL,
  // Sign out URL
  redirectSignOut: process.env.REACT_APP_AWS_COGN_REDIRECT_URL,
  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: 'code',
  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true,
  },
};

Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGN_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGN_USER_POOL_WEB_ID,
  oauth,
});

export default Auth;
