import React from 'react';
import styled from 'styled-components';
import { ILabel } from  '../../interfaces/interfaces';
import backgroundImg from '../../assets/img/ohpen-bcg.png';
import FormDescription from '../../components/FormDescription';
import FormWrapper from '../../components/FormWrapper';
import logo from '../../assets/img/ohpenlogo.png';
import SelectionForm from '../../components/SelectionForm';
import Dropdown from '../../components/Dropdown';
import SubmitButton from '../../components/SubmitButton';
import LoadingSpinner from '../../components/LoadingSpinner';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #f8f8f8;
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${backgroundImg});
  background-position: center 3%;
  background-size: 1500px;
  background-repeat: no-repeat;
`;

interface IProps {
  workspaces: ILabel[] | [];
  apis:  ILabel[] | [];
  versions:  ILabel[] | [];
  handleSubmit: (e: Event) => void; 
  isLoading: boolean;
  workspace: string;
  api: string;
  version: string;
  handleSelect: (e: { target: HTMLSelectElement}) => void;
}

const Home: React.FC<IProps> = ({
  workspaces,
  apis,
  versions,
  workspace,
  api,
  version,
  handleSubmit,
  isLoading,
  handleSelect
}) => {

  return (
    <Background>
      <BackgroundImage>
        <FormWrapper>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <FormDescription logo={logo} alt="Ohpen Logo" title="OPENAPI VIEWER">
              Welcome to our OpenAPI viewer. Here you can see all our API specs.
              <br />
              <br />
              Select your workspace, service, and version to continue.
            </FormDescription>
          )}
          <SelectionForm name="apiForm" id="apiForm" onSubmit={handleSubmit}>
            <Dropdown
              options={workspaces}
              id="workspaceSelect"
              name="workspace"
              onSelect={handleSelect}
              value={workspace}
              defaultValue="Select the workspace"
            />
            <Dropdown
              options={apis}
              id="apiSelect"
              name="api"
              onSelect={handleSelect}
              value={api}
              defaultValue="Select the API"
            />
            <Dropdown
              options={versions}
              id="versionSelect"
              name="version"
              onSelect={handleSelect}
              value={version}
              defaultValue="Select the version"
            />
          </SelectionForm>
          <SubmitButton form="apiForm" value="Show the API docs" />
        </FormWrapper>
      </BackgroundImage>
    </Background>
  )
};

export default Home;
