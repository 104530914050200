import { Alert } from 'react-bootstrap';
import { RedocStandalone } from 'redoc';

interface IProps {
  error: any;
  schemaYAML: any;
  api: any;
  version: any;
}

const Result: React.FC<IProps> = ({
  error,
  schemaYAML,
  api,
  version,
}) => (
  <main className="main-viewer">
    {error && <Alert variant="danger">{error}</Alert>}
    {api !== "" && version !== "" && schemaYAML && (
      <RedocStandalone
        spec={schemaYAML}
        options={{
          disableSearch: true,
          hideDownloadButton: true,
          hideHostname: true,
          nativeScrollbars: 'true',
          showExtensions: 'true',
          noAutoAuth: 'true',
          scrollYOffset: 80,
          menuToggle: true,
        }}
      />
    )}
  </main>
);

export default Result;
