import styled from 'styled-components';

const Text = styled.p`
  margin: 0;
  font-family: 'VisueltPro', sans-serif;
`;

const VersionDescription: React.FC = ({ children }) => <Text>{children}</Text>;

export default VersionDescription;
