import styled from 'styled-components';

interface IButtonProps {
  primary?: string;
}

interface IProps {
  primary?: string;
  onClick: any;
}

const Button = styled.button<IButtonProps>`
  background-color: ${(props) => (props.primary ? '#7d8c7f' : 'transparent')};
  color: ${(props) => (props.primary ? '#fff' : '#7d8c7f')};
  padding: 8px 12px;
  display: block;
  margin: 6px 0;
  max-height: 36px;
  border: 2px solid #7d8c7f;
  font-size: 16px;
  font-weight: 400;
  font-family: 'VisueltPro', sans-serif;
  cursor: pointer;
  display: flex;
  transition: 0.3s ease all;
  &:hover {
    background-color: ${(props) => (props.primary ? '#fff' : '#7d8c7f')};
    color: ${(props) => (props.primary ? '#7d8c7f' : '#fff')};
  }

  &:hover svg {
    color: ${(props) => (props.primary ? '#7d8c7f' : '#fff')};
  }

  svg {
  color: ${(props) => (props.primary ? '#fff' : '#7d8c7f')};
  transition: 0.3s ease color;
  margin-right: 6px;
  margin-top: -1px;
}

@media (min-width: 700px) {
  margin: 0 6px;
}
`;

const MenuButton: React.FC<IProps> = ({ children, primary, onClick }) => (
  <Button primary={primary} onClick={onClick}>
    {children}
  </Button>
);

export default MenuButton;
